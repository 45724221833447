import Session from "react-session-api";

export class SessionManagement {
  static setName(name) {
    return localStorage.setItem("name", name);
  }

  static setFirstName(firstName) {
    return localStorage.setItem("first_name", firstName);
  }

  static setLastName(lastName) {
    return localStorage.setItem("last_name", lastName);
  }

  static setCompanyID(companyID) {
    return localStorage.setItem("company_id", companyID);
  }

  static setCompanyName(companyName) {
    return localStorage.setItem("company_name", companyName);
  }

  static setEmail(email) {
    return localStorage.setItem("email", email);
  }

  static setPhone(phone) {
    return localStorage.setItem("phone_number", phone);
  }

  static setProfileImageURL(profileImageURL) {
    return localStorage.setItem("profile_image_url", profileImageURL);
  }

  static setSessionID(sessionID) {
    return localStorage.setItem("session_id", sessionID);
  }

  static setUserID(userID) {
    return localStorage.setItem("user_id", userID);
  }

  static setUserPrivileges(privileges) {
    return localStorage.setItem("privileges", JSON.stringify(privileges));
  }

  static getFirstName() {
    return localStorage.getItem("first_name");
  }

  static getLastName() {
    return localStorage.getItem("last_name");
  }

  static getName() {
    return localStorage.getItem("name");
  }

  static getEmail() {
    return localStorage.getItem("email");
  }

  static getPhone() {
    return localStorage.getItem("phone_number");
  }

  static getCompanyID() {
    return localStorage.getItem("company_id");
  }

  static getCompanyOperatorID() {
    return localStorage.getItem("company_operator_id");
  }

  static getCompanyLogoURL() {
    return localStorage.getItem("company_logo_url");
  }

  static getCompanyName() {
    return localStorage.getItem("company_name");
  }

  static getProfileImageURL() {
    return localStorage.getItem("profile_image_url");
  }

  static getSessionID() {
    return localStorage.getItem("session_id");
  }

  static getUserID() {
    return localStorage.getItem("user_id");
  }

  static getUserPrivileges() {
    return JSON.parse(localStorage.getItem("privileges"));
  }

  static setAuthenticationStatus(status) {
    return localStorage.setItem("is_authentication", status);
  }

  static isAuthenticated() {
    const authenticationStatus = localStorage.getItem("is_authentication");
    if (authenticationStatus === null || authenticationStatus === "no")
      return false;

    return true;
  }

  static setCompanyLogoURL(companyLogoURL) {
    return localStorage.setItem("company_logo_url", companyLogoURL);
  }

  static setCompanyOperatorID(companyOperatorID) {
    return localStorage.setItem("company_operator_id", companyOperatorID);
  }

  static getIntracityShipmentProcessor() {
    return localStorage.getItem("intracity_shipment_processor");
  }

  static getInterstateShipmentProcessor() {
    return localStorage.getItem("interstate_shipment_processor");
  }

  static getInternationalShipmentProcessor() {
    return localStorage.getItem("international_shipment_processor");
  }

  static setIntracityShipmentProcessor(shipmentProcessor) {
    return localStorage.setItem("intracity_shipment_processor", shipmentProcessor);
  }

  static setInterstateShipmentProcessor(shipmentProcessor) {
    return localStorage.setItem("interstate_shipment_processor", shipmentProcessor);
  }

  static setInternationalShipmentProcessor(shipmentProcessor) {
    return localStorage.setItem("international_shipment_processor", shipmentProcessor);
  }

  static setEmployeeType(employee_type) {
    return localStorage.setItem("employee_type", employee_type);
  }

  static getIsWhatsappSet() {
    return localStorage.getItem("is_whatsapp_set");
  }

  static setIsWhatsappSet(isWhatsappSet) {
    return localStorage.setItem("is_whatsapp_set", isWhatsappSet);
  }

  static getCurrentPlan() {
    return localStorage.getItem("current_plan");
  }

  static setCurrentPlan(currentPlan) {
    return localStorage.setItem("current_plan", currentPlan);
  }

  static getEmployeeType(currentPlan) {
    return localStorage.getItem("employee_type");
  }
}
