import "../styles/home.scss";
import "../styles/single-shipment.scss";
import "../styles/components.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import {useNavigate, useParams} from "react-router-dom";
import ShipmentTimeline from "./Widgets/ShipmentTimeline";
import { useEffect, useState } from "react";
import ShipmentInformation from "./Widgets/ShipmentInformation";
import {getShipmentDetailsURL} from "../library/URLs";
import axios from "axios";
import { UserProfileData } from "../library/constants";
import {ErrorNotification, SuccessNotification} from "../components/Notifications";
import ShipmentPath from "./Widgets/ShipmentPath";
import DHLShipmentContainer from "./Widgets/DHLShipmentContainer";
import RouteShipmentDialog from "../modals/RouteShipmentDialog";
import CancelShipmentDialog from "../modals/CancelShipmentDialog";
import ReRouteShipmentDialog from "../modals/ReRouteShipmentDialog";
import moment from "moment";
import ApproveInternationalShipmentDialog from "../modals/ApproveInternationalShipmentDialog";
import * as React from "react";
import styled from "styled-components";
import {SessionManagement} from "../library/SessionManagement";
import SubscriptionInactive from "../components/SubscriptionInactive";
// import ApproveInterstateShipmentDialog from "../../modals/ApproveInterstateShipmentDialog";
// import ApproveMultiLevelShipmentDialog from "../modals/ApproveMultiLevelShipmentDialog";
// import ApproveLocalShipmentDialog from "../modals/ApproveLocalShipmentDialog";
import ApproveImportShipmentDialog from "../modals/ApproveImportShipmentDialog";
import ApproveInterstateShipmentDialog from "../modals/ApproveInterstateShipmentDialog";
import ApproveMultiLevelShipmentDialog from "../modals/ApproveMultiLevelShipmentDialog";
import ApproveRedstarLocalShipmentDialog from "../modals/ApproveRedstarLocalShipmentDialog";
import ApproveStationedShipmentDialog from "../modals/ApproveStationedShipmentDialog";


const LoadingContainer = styled.div`
  height: 155px;
  text-align: center;
  display: flex;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  border-radius: 5px;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const SingleShipment = () => {
  const [showApproveInternationalShipmentDialog, setShowApproveInternationalShipmentDialog] = useState(false);
  const [showApproveImportShipmentDialog, setShowApproveImportShipmentDialog] = useState(false);
  const [showApproveInterstateShipmentDialog, setShowApproveInterstateShipmentDialog] = useState(false);
  const [showApproveLocalShipmentDialog, setShowApproveLocalShipmentDialog] = useState(false);
  const [showApproveMultiLevelShipmentDialog, setShowApproveMultiLevelShipmentDialog] = useState(false);
  const [showApproveRedstarLocalShipmentDialog, setShowApproveRedstarLocalShipmentDialog] = useState(false);

  const [isRetrievingDetails, setIsRetrievingDetails] = useState(true);

  const params = useParams();
  const navigator = useNavigate();
  const shipmentID = params["shipmentID"];

  const [isShowRouteShipmentDialogVisible, setShowRouteShipmentDialogVisibility] = useState(false);
  const [isShowReRouteShipmentDialogVisible, setShowReRouteShipmentDialogVisibility] = useState(false);
  const [isShowCancelShipmentDialogVisible, setShowCancelShipmentDialogVisibility] = useState(false);

  const [shipmentTime, setShipmentTime] = useState("");
  const [pickupLocation, setPickupLocation] = useState("");
  const [dropoffLocation, setDropoffLocation] = useState("");
  const [senderID, setSenderID] = useState("");
  const [senderType, setSenderType] = useState("");
  const [senderName, setSenderName] = useState("");
  const [senderPhoneNumber, setSenderPhoneNumber] = useState("");
  const [receiverName, setReceiverName] = useState("");
  const [receiverPhoneNumber, setReceiverPhoneNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [status, setStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [statusClass, setStatusClass] = useState("");
  const [description, setDescription] = useState("");
  const [shipmentNumber, setShipmentNumber] = useState("");
  const [shipmentType, setShipmentType] = useState("");
  const [shipmentLevels, setShipmentLevels] = useState("1");
  const [dropoffCoordinates, setDropoffCoordinates] = useState("");
  const [pickupCoordinates, setPickupCoordinates] = useState("");
  const [approvedBy, setApprovedBy] = useState("");
  const [weight, setWeight] = useState("");
  const [riderName, setRiderName] = useState("");
  const [itemValue, setItemValue] = useState("");
  const [itemValueCurrency, setItemValueCurrency] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [receiverEmail, setReceiverEmail] = useState("");

  const [dhlProcessed, setDHLProcessed] = useState("");
  const [processor, setProcessor] = useState("");
  const [shipmentPlan, setShipmentPlan] = useState("");
  const [waybillURL, setWaybillURL] = useState("");
  const [invoiceURL, setInvoiceURL] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [manualApprove, setManualApprove] = useState(false);
  const [requestPickup, setRequestPickup] = useState("");
  const [serviceStation, setServiceStation] = useState("");

  const [insurance, setInsurance] = useState("");
  const [pickupType, setPickupType] = useState("");
  const [packageType, setPackageType] = useState("");
  const [packaging, setPackaging] = useState("");

  const [senderState, setSenderState] = useState("");
  const [receiverState, setReceiverState] = useState("");

  const [approvalMenu, setApprovalMenu] = useState(false);

  const toggleApprovalMenu = () => {
    setApprovalMenu(!approvalMenu);
  }

  const toggleRouteShipmentDialog = (type) => {
    type === "open" ? setShowRouteShipmentDialogVisibility(true) : setShowRouteShipmentDialogVisibility(false);
  }

  const toggleReRouteShipmentDialog = (type) => {
    type === "open" ? setShowReRouteShipmentDialogVisibility(true) : setShowReRouteShipmentDialogVisibility(false);
  }

  const toggleCancelShipmentDialog = (type) => {
    type === "open" ? setShowCancelShipmentDialogVisibility(true) : setShowCancelShipmentDialogVisibility(false);
  }

  const data = {
    shipment_id: shipmentID,
  };

  const newData = { ...data, ...UserProfileData() };

  const toggleInternationalApproveShipment = (type) => {
    type === "open" ? setShowApproveInternationalShipmentDialog(true) : setShowApproveInternationalShipmentDialog(false);
  };

  const toggleImportApproveShipment = (type) => {
    type === "open" ? setShowApproveImportShipmentDialog(true) : setShowApproveImportShipmentDialog(false);
  };

  const toggleInterstateApproveShipment = (type) => {
    type === "open" ? setShowApproveInterstateShipmentDialog(true) : setShowApproveInterstateShipmentDialog(false);
  };

  const openLocalApproveShipmentDialog = () => {
    setShowApproveLocalShipmentDialog(true);
  }

  const closeLocalApproveShipmentDialog = (type) => {
    setShowApproveLocalShipmentDialog(false);
    if (type === "Refresh"){
      window.location.reload();
    }
  }

  const openInterstateApproveShipment = () => {
    setShowApproveInterstateShipmentDialog(true);
  }

  const closeInternationalApproveShipment = (type) => {
    setShowApproveInternationalShipmentDialog(false);
    if (type === "Refresh"){
      window.location.reload();
    }
  }

  const closeImportApproveShipment = (type) => {
    setShowApproveImportShipmentDialog(false);
    if (type === "Refresh"){
      window.location.reload();
    }
  }

  const closeInterstateApproveShipment = (type) => {
    setShowApproveInterstateShipmentDialog(false);
    if (type === "Refresh"){
      window.location.reload();
    }
  }

  const closeLocalApproveShipment = (type) => {
    setShowApproveLocalShipmentDialog(false);
    if (type === "Refresh"){
      window.location.reload();
    }
  }

  const openMultiLevelApproveShipment = () => {
    setShowApproveMultiLevelShipmentDialog(true);
  }

  const closeMultiLevelApproveShipment = (type) => {
    setShowApproveMultiLevelShipmentDialog(false);
    if (type === "Refresh"){
      window.location.reload();
    }
  }

  const openRedstarLocalApproveShipment = () => {
    setShowApproveRedstarLocalShipmentDialog(true);
  }

  const closeRedstarLocalApproveShipment = (type) => {
    setShowApproveRedstarLocalShipmentDialog(false);
    if(type === "Refresh") {
      window.location.reload();
    }
  }

  const goToSender = () => {
    if (senderType === "Customer"){
      navigator("/customer/" + senderID);
    } else if (senderType === "Admin"){
      navigator("/admin/" + senderID);
    }
  }

  useEffect(() => {
    axios
      .post(getShipmentDetailsURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        setIsRetrievingDetails(false);
        if (response["data"]["success"] === false) return;

        const shipmentDetails = response["data"];

        const sender = shipmentDetails["data"]["user_id"];
        const senderType = sender.toString().split("-")[0];
        const senderID = sender.toString().split("-")[1];

        const senderName = shipmentDetails["data"]["sender_name"];
        const senderPhoneNumber = shipmentDetails["data"]["sender_phone_no"];
        const receiverName = shipmentDetails["data"]["recipient_name"];
        const receiverPhoneNumber = shipmentDetails["data"]["recipient_phone_no"];
        const amount = shipmentDetails["data"]["total_amount"];
        const paymentMethod = shipmentDetails["data"]["payment_method"];
        const pickupLocation = shipmentDetails["data"]["pick_up_location"];
        const dropoffLocation = shipmentDetails["data"]["drop_off_location"];
        const status = shipmentDetails["data"]["status"];
        const paymentStatus = shipmentDetails["data"]["paid_status"];
        const description = shipmentDetails["data"]["item_name"];
        const riderName = shipmentDetails["data"]["rider_name"];
        const itemValue = shipmentDetails["data"]["item_value"];
        const itemValueCurrency = shipmentDetails["data"]["item_value_currency"];
        const weight = shipmentDetails["data"]["weight"];
        const senderEmail = shipmentDetails["data"]["sender_email"];
        const receiverEmail = shipmentDetails["data"]["recipient_email"];

        const senderState = shipmentDetails["data"]["sender_state"];
        const receiverState = shipmentDetails["data"]["receiver_state"];

        const insurance = shipmentDetails["data"]["insurance"];
        const packageType = shipmentDetails["data"]["package_type"];
        const pickupType = shipmentDetails["data"]["pickup_type"];
        const packaging = shipmentDetails["data"]["packaging"];
        const packagingID = shipmentDetails["data"]["packaging_id"];

        const shipmentNumber = shipmentDetails["data"]["shipment_no"];
        const shipmentType = shipmentDetails["data"]["shipment_type"];
        const shipmentLevels = shipmentDetails["data"]["shipment_levels"];

        const dropoffCoordinates =
            shipmentDetails["data"]["drop_off_latitude"] +
            "," +
            shipmentDetails["data"]["drop_off_longitude"];
        const pickupCoordinates =
            shipmentDetails["data"]["pick_up_latitude"] +
            "," +
            shipmentDetails["data"]["pick_up_longitude"];

        const approvedBy = shipmentDetails["data"]["approved_by"];

        const dhlProcessed = shipmentDetails["data"]["dhl_processed"];
        const processor = shipmentDetails["data"]["processor"];
        const shipmentPlan = shipmentDetails["data"]["shipment_plan"];
        const waybillURL = shipmentDetails["data"]["pdf_document"];
        const invoiceURL = shipmentDetails["data"]["invoice_url"];
        const shipmentNum = shipmentDetails["data"]["shipment_no"];
        const trackingNumber =
            shipmentDetails["data"]["shipment_tracking_number"];
        const service_station = shipmentDetails["data"]["service_station"];
        const request_pickup = shipmentDetails["data"]["request_pickup"];

        const timestamp = shipmentDetails["data"]["date_time"];
        const shipmentTime = moment(timestamp).format('ddd MMM Do, h:mm A');

        setShipmentTime(shipmentTime);

        const statusClass = status.toString().replaceAll(" ", "");
        setStatusClass(statusClass);

        if (processor === "TerminalAfrica"){
          setPickupCoordinates("6.596413,3.353785");
          setDropoffCoordinates("6.614775,3.356861");
        } else {
          setPickupCoordinates(pickupLocation);
          setDropoffCoordinates(dropoffLocation);
        }

        setSenderID(senderID);
        setSenderType(senderType);
        setSenderName(senderName);
        setSenderPhoneNumber(senderPhoneNumber);
        setReceiverName(receiverName);
        setAmount(amount);
        setReceiverPhoneNumber(receiverPhoneNumber);
        setPaymentMethod(paymentMethod);
        setSenderEmail(senderEmail);
        setReceiverEmail(receiverEmail);

        setInsurance(insurance);
        setPackageType(packageType);
        setPickupType(pickupType);
        setPackaging(packaging);

        setStatus(status);
        setPaymentStatus(paymentStatus);
        setDescription(description);
        setShipmentType(shipmentType);
        setApprovedBy(approvedBy);
        setPickupLocation(pickupLocation);
        setDropoffLocation(dropoffLocation);
        setShipmentLevels(shipmentLevels);

        setShipmentNumber(shipmentNumber);

        setSenderState(senderState);
        setReceiverState(receiverState);

        console.log(senderState);

        setRiderName(riderName);
        setItemValue(itemValue === undefined ? "" : itemValue);
        setItemValueCurrency(itemValueCurrency === undefined ? "" : itemValueCurrency);
        setWeight(weight === undefined ? "" : weight);
        setShipmentPlan(shipmentPlan);
        setDHLProcessed(dhlProcessed);
        setProcessor(processor);
        setWaybillURL(waybillURL);
        setInvoiceURL(invoiceURL);
        setTrackingNumber(trackingNumber);
        setRequestPickup(request_pickup);
        setServiceStation(service_station);
      });
  }, []);

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />

          <div className="d-flex flex-row justify-content-between mb-2 mx-3">
            <h5 className="header">Shipment Number - {shipmentNumber}</h5>
            <span>{shipmentTime}</span>
          </div>
          <div className="d-flex flex-row mx-3 justify-content-between">
            {
              shipmentLevels === "1" ? status === "Pending Approval" ? shipmentType === "Local" ? 
                shipmentPlan === "Express Standard" ? (
                  <div>
                    <button style={{marginRight:15}} className="confirm-button" onClick={openRedstarLocalApproveShipment}>APPROVE SHIPMENT</button>
                    <button onClick={(e) => toggleCancelShipmentDialog("open")} className="cancel-button">CANCEL SHIPMENT</button>
                  </div>
                  ) :
                  <div>
                    <button style={{marginRight:15}} className="confirm-button" onClick={(e) => openLocalApproveShipmentDialog("open")}>APPROVE SHIPMENT</button>
                    <button onClick={(e) => toggleCancelShipmentDialog("open")} className="cancel-button">CANCEL SHIPMENT</button>
                  </div> :
                  shipmentType === "International" ?
                  <div>
                    <div className="user-menu-container d-inline">
                      <button style={{marginRight:15}} className="confirm-button" onClick={toggleApprovalMenu}>APPROVE SHIPMENT</button>
                      {/* <button style={{marginRight:15}} className="confirm-button" onClick={(e) => toggleInternationalApproveShipment("open")}>APPROVE SHIPMENT</button> */}
                      <div className={`user-menu ${approvalMenu ? "user-menu-visible" : null}`} style={{left:"3rem"}}>
                          <span 
                            onClick={(e) => {
                              setManualApprove(false);
                              toggleInternationalApproveShipment("open");
                              setApprovalMenu(false)
                            }} >
                              Automatic Approval
                            </span>
                          <span
                            onClick={(e) => {
                              setManualApprove(true);
                              toggleInternationalApproveShipment("open");
                              setApprovalMenu(false)
                            }}
                          >Manual Approval</span>
                      </div>
                    </div>
                    <button onClick={(e) => toggleCancelShipmentDialog("open")} className="cancel-button">CANCEL SHIPMENT</button>
                  </div> :
                    shipmentType === "Import" ?
                    <div>
                      <div className="user-menu-container d-inline">
                        <button style={{marginRight:15}} className="confirm-button" onClick={toggleApprovalMenu}>APPROVE SHIPMENT</button>
                        {/* <button style={{marginRight:15}} className="confirm-button" onClick={(e) => toggleImportApproveShipment("open")}>APPROVE SHIPMENT</button> */}
                        <div className={`user-menu ${approvalMenu ? "user-menu-visible" : null}`} style={{left:"3rem"}}>
                            <span 
                              onClick={(e) => {
                                setManualApprove(false);
                                toggleImportApproveShipment("open");
                                setApprovalMenu(false)
                              }} >
                                Automatic Approval
                              </span>
                            <span
                              onClick={(e) => {
                                setManualApprove(true);
                                toggleImportApproveShipment("open");
                                setApprovalMenu(false)
                              }}
                            >Manual Approval</span>
                        </div>
                      </div>
                      <button onClick={(e) => toggleCancelShipmentDialog("open")} className="cancel-button">CANCEL SHIPMENT</button>
                    </div> :
                  <div>
                    <div className="user-menu-container d-inline">
                      <button style={{marginRight:15}} className="confirm-button" onClick={toggleApprovalMenu}>APPROVE SHIPMENT</button>
                      {/* <button style={{marginRight:15}} className="confirm-button" onClick={(e) => toggleInterstateApproveShipment("open")}>APPROVE SHIPMENT</button> */}
                      <div className={`user-menu ${approvalMenu ? "user-menu-visible" : null}`} style={{left:"3rem"}}>
                          <span 
                            onClick={(e) => {
                              setManualApprove(false);
                              toggleInterstateApproveShipment("open");
                              setApprovalMenu(false)
                            }} >
                              Automatic Approval
                            </span>
                          <span
                            onClick={(e) => {
                              setManualApprove(true);
                              toggleInterstateApproveShipment("open");
                              setApprovalMenu(false)
                            }}
                          >Manual Approval</span>
                      </div>
                    </div>
                    <button onClick={(e) => toggleCancelShipmentDialog("open")} className="cancel-button">CANCEL SHIPMENT</button>
                  </div> : status === "Cancelled" || status === "Delivered" ? <div></div> :
                  <div>
                    <button onClick={(e) => toggleCancelShipmentDialog("open")} className="cancel-button">CANCEL SHIPMENT</button>
                  </div> : null
            }

            {
              shipmentLevels > 1 ? status === "Pending Approval" ?
                  <div>
                    <div className="user-menu-container d-inline">
                      <button style={{marginRight:15}} className="confirm-button" onClick={toggleApprovalMenu}>APPROVE SHIPMENT</button>
                      {/* <button style={{marginRight:15}} className="confirm-button" onClick={(e) => openMultiLevelApproveShipment()}>APPROVE SHIPMENT</button> */}
                      <div className={`user-menu ${approvalMenu ? "user-menu-visible" : null}`} style={{left:"3rem"}}>
                          <span 
                            onClick={(e) => {
                              setManualApprove(false);
                              openMultiLevelApproveShipment();
                              setApprovalMenu(false)
                            }} >
                              Automatic Approval
                            </span>
                          <span
                            onClick={(e) => {
                              setManualApprove(true);
                              openMultiLevelApproveShipment();
                              setApprovalMenu(false)
                            }}
                          >Manual Approval</span>
                      </div>
                    </div>
                    <button onClick={(e) => toggleCancelShipmentDialog("open")} className="cancel-button">CANCEL SHIPMENT</button>
                  </div> : status === "Cancelled" || status === "Delivered" ? <div></div> :
                  <div>
                    <button onClick={(e) => toggleCancelShipmentDialog("open")} className="cancel-button">CANCEL SHIPMENT</button>
                  </div> : null
            }
            <div className={`status ${statusClass}`}>
              {status}
            </div>
          </div>

          {
            shipmentLevels === "1" ?
                <>
                  <ApproveInternationalShipmentDialog
                      shipmentID={shipmentID}
                      isModalVisible={showApproveInternationalShipmentDialog}
                      handleCloseDialog={closeInternationalApproveShipment}
                      manualApprove={manualApprove}
                  />

                  <ApproveImportShipmentDialog
                      shipmentID={shipmentID}
                      isModalVisible={showApproveImportShipmentDialog}
                      handleCloseDialog={closeImportApproveShipment}
                      manualApprove={manualApprove}
                  />

                  <ApproveInterstateShipmentDialog
                      shipmentID={shipmentID}
                      isModalVisible={showApproveInterstateShipmentDialog}
                      handleCloseDialog={closeInterstateApproveShipment}
                      senderState={senderState}
                      receiverState={receiverState}
                      manualApprove={manualApprove}
                  />

                  <ApproveRedstarLocalShipmentDialog
                      shipmentID={shipmentID}
                      isModalVisible={showApproveRedstarLocalShipmentDialog}
                      handleCloseDialog={closeRedstarLocalApproveShipment}
                      senderState={"Lagos"}
                      receiverState={"Lagos"}
                  />
                </> :
                (serviceStation && serviceStation !== "") ? (
                  <>
                    <ApproveStationedShipmentDialog
                        shipmentID={shipmentID}
                        isModalVisible={showApproveMultiLevelShipmentDialog}
                        handleCloseDialog={closeMultiLevelApproveShipment}
                        senderState={senderState}
                        receiverState={receiverState}
                        shipmentType={shipmentType}
                        manualApprove={manualApprove}
                    />
                  </>
                ) : (
                  <>
                    <ApproveMultiLevelShipmentDialog
                        shipmentID={shipmentID}
                        isModalVisible={showApproveMultiLevelShipmentDialog}
                        handleCloseDialog={closeMultiLevelApproveShipment}
                        senderState={senderState}
                        receiverState={receiverState}
                        shipmentType={shipmentType}
                        manualApprove={manualApprove}
                    />
                  </>
                )
          }

          <RouteShipmentDialog
            shipmentID={shipmentID}
            isModalVisible={isShowRouteShipmentDialogVisible}
            handleCloseDialog={(e) => toggleRouteShipmentDialog("close")}
          />

          <ReRouteShipmentDialog
              shipmentID={shipmentID}
              isModalVisible={isShowReRouteShipmentDialogVisible}
              handleCloseDialog={(e) => toggleReRouteShipmentDialog("close")}
          />

          <CancelShipmentDialog
              shipmentID={shipmentID}
              isModalVisible={isShowCancelShipmentDialogVisible}
              handleCloseDialog={(e) => toggleCancelShipmentDialog("close")}
          />

          <div className="container-fluid">
            <div className="row mt-4">
              <div className="col-xl-6 col-md-12">
                <span className="header">Sender Details</span>
                {
                  isRetrievingDetails === true ?
                      <LoadingContainer>
                        <LoadingSpinner className="spinner-border spinner-grow-sm"/>
                      </LoadingContainer> :
                      <div className="card bg-light border-0 shadow">
                        <div className="card-body shipper-container">
                          <div className="d-flex justify-content-between">
                            <div>
                              <span className="title">Name</span>
                              <p onClick={(e) => goToSender()} className="sender-name">{senderName}</p>
                            </div>
                            <div>
                              <span className="title">Phone Number</span>
                              <p>{senderPhoneNumber}</p>
                            </div>
                          </div>
                          <div className="">
                            <span className="title">Email</span>
                            <p>{senderEmail}</p>
                          </div>
                          <div className="mt-4">
                            <span className="title">Pickup Location</span>
                            <p>{pickupLocation}</p>
                          </div>
                        </div>
                      </div>
                }
              </div>

              <div className="col-xl-6 col-md-12">
                <span className="header">Receiver Details</span>
                {
                  isRetrievingDetails === true ?
                  <LoadingContainer>
                    <LoadingSpinner className="spinner-border spinner-grow-sm"/>
                  </LoadingContainer> :
                  <div className="card bg-light border-0 shadow">
                    <div className="card-body shipper-container">
                      <div className="d-flex justify-content-between">
                        <div>
                          <span className="title">Name</span>
                          <p>{receiverName}</p>
                        </div>
                        <div>
                          <span className="title">Phone Number</span>
                          <p>{receiverPhoneNumber}</p>
                        </div>
                      </div>
                      <div className="">
                        <span className="title">Email</span>
                        <p>{receiverEmail}</p>
                      </div>
                      <div className="mt-4">
                        <span className="title">Dropoff Location</span>
                        <p>{dropoffLocation}</p>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>

            <div className="row mt-4">
              <ShipmentInformation
                isRetrieving={isRetrievingDetails}
                amount={amount}
                paymentStatus={paymentStatus}
                description={description}
                approvedBy={approvedBy}
                weight={weight}
                processor={
                  processor === "Self" ? riderName : processor
                }
                itemValue={itemValue}
                itemValueCurrency={itemValueCurrency}
                paymentMethod={paymentMethod}
                shipmentID={shipmentID}
                shipmentPlan={shipmentPlan}
                insurance={insurance}
                pickupType={pickupType}
                packageType={packageType}
                packaging={packaging}
                shipmentNumber={shipmentNumber}
                requestPickup={requestPickup}
                serviceStation={serviceStation}
              />
            </div>

            <div className="row mt-4">
              {processor === "Self" ? (null
                  // <ShipmentPath
                  //     pickupCoordinates={pickupCoordinates}
                  //     dropoffCoordinates={dropoffCoordinates}
                  // />
              ) : (
                <DHLShipmentContainer
                  processor={processor}
                  dhlProcessed={dhlProcessed}
                  waybillURL={waybillURL}
                  trackingNumber={trackingNumber}
                  invoiceURL={invoiceURL}
                  status={status}
                  shipmentID={shipmentID}
                />
              )}

              <ShipmentTimeline shipmentID={shipmentID} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleShipment;
