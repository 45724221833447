const domain = "https://admin.routely.co/backend/engine/v1/actions/";
// const domain = "https://test.admin.routely.co/backend/engine/v1/actions/";

export const loginURL = domain + "login";
export const registerURL = domain + "register";
export const logoutURL = domain + "logout";
export const createInternationalShipmentURL = domain + "create-inter-shipment.php";
export const createTerminalInterShipment = domain + "create-inter-shipment-terminal.php";
export const getDashboardStatisticsURL = domain + "get-dashboard-stats";
export const getTransactionsURL = domain + "get-transactions";
export const getShipmentsURL = domain + "get-shipments-new";
export const getCustomersURL = domain + "get-all-users";
export const getShipmentTimelineURL = domain + "get-shipment-timeline";
export const getShipmentPath = domain + "get-shipment-path";
export const getShipmentDetailsURL = domain + "get-shipment-by-shipment-id";
export const getRecentTransporterActivitiesURL = domain + "get-rider-activities";
export const getAllTransporterActivitiesURL = domain + "get-all-riders-activities";
export const getAllUsersActivitiesURL = domain + "get-all-users-activities";
export const getCompanyWalletDetails = domain + "get-virtual-account";
export const approveShipmentURL = domain + "approve-shipment.php";
export const getCustomerDetailsURL = domain + "get-user-by-user-id";
export const makeContractUserURL = domain + "make-contract-user";
export const makeRegularUserURL = domain + "make-regular-user";
export const getShipmentsByUserURL = domain + "get-shipments-by-user-id";
export const searchForCustomersURL = domain + "search-for-users";
export const getContractDetailsUserURL = domain + "get-contract-details-by-user";
export const getDHLPickupLocationsURL = domain + "get-pickup-details";
export const getPackagingURL = domain + "get-packaging";
export const calculateDHLURL = domain + "calculate-shipment";
export const calculateTerminalURL = domain + "calculate-terminal-shipment";
export const getAllAdminsURL = domain + "get-all-employees";
export const cancelShipmentURL = domain + "cancel-shipment";
export const getAddressSuggestionsURL = domain + "get-address-suggestions";
export const calculateLocalCostURL = domain + "calculate-shipment-cost";
export const createLocalShipmentURL = domain + "create-shipment";
export const getAllTransportersURL = domain + "get-all-riders";
export const rerouteShipmentURL = domain + "reroute-shipment";
export const routeShipmentURL = domain + "route-shipment";
export const topupUserWalletURL = domain + "topup-user-wallet";
export const deductUserWalletURL = domain + "deduct-wallet-balance";
export const markShipmentAsPaidURL = domain + "mark-shipment-as-paid";
export const getCustomerWalletHistoryURL = domain + "get-wallet-history";
export const getCustomerWalletDetailsURL = domain + "get-wallet-balance";
export const getAdminDetailsURL = domain + "get-employee-by-employee-id";
export const getCustomerStatsURL = domain + "get-user-stats";
export const getCustomerReferredUsersURL = domain + "get-user-referred-users";
export const getCustomerReferralDetailsURL = domain + "get-user-referral-details";
export const getTransactionDetailsURL = domain + "get-transaction-details";
export const getUnseenNotificationsURL = domain + "get-unseen-notifications";
export const getNotificationsURL = domain + "get-notifications";
export const markNotificationsAsReadURL = domain + "mark-notifications-as-read";
export const getUserReportsURL = domain + "get-user-reports";
export const getTransporterReportsURL = domain + "get-transporter-reports";
export const createAdminURL = domain + "create-employee";
export const uploadImageURL = domain + "upload-image";
export const createTransporterURL = domain + "create-rider";
export const editAdminPrivilegesURL = domain + "edit-employee-privileges";
export const getAdminActivitiesURL = domain + "get-employee-activities";
export const getAdminPrivilegesURL = domain + "get-employee-privileges";
export const getShipmentPricingURL = domain + "get-price-settings";
export const changePasswordURL = domain + "change-password";
export const updateProfileURL = domain + "update-profile";
export const deleteEmployeeURL = domain + "delete-employee";
export const requestResetCodeURL = domain + "request-reset-password-code";
export const resetPasswordURL = domain + "reset-password";
export const getWhatsappChatListURL = domain + "get-whatsapp-chat-list";
export const sendWhatsappMessageURL = domain + "send-whatsapp-message";
export const getConversationMessagesURL = domain + "get-whatsapp-conversation-messages";
export const validateAddressURL = domain + "validate-address";
export const getCompanySettingsURL = domain + "get-company-settings";
export const getStatesURL = domain + "get-states";
export const getCitiesURL = domain + "get-cities";
export const updateAccountSettingsURL = domain + "update-account-settings";
export const getCompanySettings1URL = domain + "get-company-settings1";
export const searchDHLCityURL = domain + "search-dhl-cities";
export const getAllCustomerWalletURL = domain + "get-all-users-wallet";
export const searchForShipmentsURL = domain + "search-for-shipments";
export const getInterstate3PLPricingURL = domain + "get-interstate-3pl-pricing";
export const updateInterstate3PLPricingURL = domain + "set-interstate-3pl-pricing";
export const getInternational3PLPricingURL = domain + "get-international-3pl-pricing";
export const updateInternational3PLPricingURL = domain + "set-international-3pl-pricing";
export const getStationsURL = domain + "get-stations";
export const getTransporterDetailsURL = domain + "get-rider-by-rider-id";
export const deleteTransporterURL = domain + "delete-rider";
export const deactivateTransporterURL = domain + "suspend-rider";
export const activateTransporterURL = domain + "activate-rider";
export const editTransporterURL = domain + "edit-transporter-details";
export const resetTransporterPasswordURL = domain + "reset-rider-password";
export const getAramexAccountURL = domain + "get-aramex-account";
export const getRedstarCitiesByStateURL = domain + "get-redstar-cities-by-state";
export const getRedstarCitiesURL = domain + "get-redstar-cities";
export const getShipmentLevelsURL = domain + "get-shipment-levels";
export const getShipmentTrackingNumbersURL = domain + "get-shipment-tracking-numbers";
export const uploadWaybillURL = domain + "upload-waybill";
export const getShipmentsReportURL = domain + "get-report-shipments.php";
export const getReportUsersURL = domain + "get-report-users.php";
export const getCarriersPriceURL = domain + "get-cost-prices.php";
export const getShipmentPackagesURL = domain + "get-shipment-packages";
export const updatePackageItemHSCodeURL = domain + "edit-item-hscodes.php";
export const getCurrenciesRateUrl = domain + "get-currencies-rate.php";
export const updateCurrencyRateUrl = domain + "update-currency-rate.php";
export const getMarginRateUrl = domain + "get-margin-rate.php";
export const updateMarginRateUrl = domain + "update-margin-rate.php";
export const getCashbacksUrl = domain + "get-cashbacks.php";
export const addCashbackUrl = domain + "add-cashback.php";
export const updateCashbackUrl = domain + "update-cashback.php";
export const updateCashbackStatusUrl = domain + "update-cashback-status.php";
export const getCashbackCustomersUrl = domain + "reward-customers.php";
export const fundCashbackUrl = domain + "fund-cashback.php";
export const getDashboardStatsUrl = domain + "get-dashboard-stats.php";
export const getDashboardStats2Url = domain + "get-dashboard-stats2.php";
export const uploadRateDocumentUrl = "https://ship.routely.co/backend/engine/v1/actions/upload-rates.php";